.gjs-fields {
  width: 100%;
}
.gjs-field {
  background-color: var(--colors-light100);
  display: flex;
  flex: 1;
}

.gjs-fields .gjs-field select,
.gjs-field.gjs-field-text input[type="text"],
.gjs-input-holder input[type="text"] {
  transition: var(--transition-fluid);
  background-color: var(--colors-light100);
  border-radius: var(--border-radius);
  border: 1px solid var(--colors-dark100);
  color: 1px solid var(--colors-dark900);
  height: var(--input-height);
  padding-left: var(--input-padding);
}
.gjs-field.gjs-field-text input[type="text"]:hover,
.gjs-field.gjs-field-text input[type="text"]:focus,
.gjs-fields .gjs-field select:hover,
.gjs-fields .gjs-field select:focus,
.gjs-input-holder input[type="text"]:hover,
.gjs-input-holder input[type="text"]:focus {
  border: 1px solid var(--colors-dark300);
}

.gjs-input-holder,
.gjs-field-wrp,
.gjs-field.gjs-select > span,
.gjs-sm-input-holder {
  width: 100%;
}
/* arrow for select input */
.gjs-field.gjs-select .gjs-sel-arrow {
  z-index: 1;
  right: var(--input-padding);
}
.gjs-field.gjs-select .gjs-sel-arrow .gjs-d-s-arrow {
  border-top-color: var(--colors-dark100);
}
.gjs-sel-arrow .gjs-d-s-arrow:hover {
  border-top-color: var(--colors-dark300);
}

.gjs-field-arrows .gjs-field-arrow-u {
  border-bottom-color: var(--colors-dark600);
}
.gjs-field-arrows .gjs-field-arrow-u:hover {
  border-bottom-color: var(--colors-dark900);
}
.gjs-field-arrows .gjs-field-arrow-d {
  border-top-color: var(--colors-dark600);
}
.gjs-field-arrows .gjs-field-arrow-d:hover {
  border-top-color: var(--colors-dark900);
}

.gjs-field.gjs-field-integer input[type="text"] {
  padding-right: 40px;
}

.gjs-field.gjs-field-integer .gjs-field-arrows {
  right: calc(var(--input-padding) + var(--input-units-width));
}

/* dropdown alongside input*/
.gjs-field .gjs-field-units {
  position: static;
  width: var(--input-units-width);
  margin-left: var(--input-gap);
}

.gjs-fields .gjs-sm-field .gjs-sm-property {
  border-bottom: none;
}
