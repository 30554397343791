:root {
  /* Base font size for the typography scale. */
  font-size: 16px;
  line-height: 1.35;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}

html,
body,
#root,
[data-reactroot] {
  min-height: 100vh;
}

body {
  background-color: #f8f9fd;
}

p {
  margin: 0;
}

a,
button,
div,
input,
textarea,
fieldset {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

button,
fieldset {
  appearance: none;
  background-color: initial;
  border: none;
  margin: initial;
  padding: initial;
  text-decoration: none;
}

button:not(:disabled) {
  cursor: pointer;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.htValid:not(.htInvalid) {
  background-color: #b1f3e5;
}

.react-flow__controls-button {
  transition: 0.1s ease-in-out;
  color: var(--colors-dark500) !important;
}

.react-flow__controls-button:hover {
  transition: 0.1s ease-in-out;
  color: var(--colors-dark700) !important;
}
