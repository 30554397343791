.gjs-sm-property.gjs-sm-color.gjs-sm-property__background-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gjs-field.gjs-field-color {
  position: relative;
}

.gjs-field.gjs-field-color .gjs-field-colorp {
  border: none;
  position: absolute;
  right: var(--input-padding);
  top: 50%;
  transform: translateY(-50%);
  width: var(--color-input-preview-size);
  height: var(--color-input-preview-size);
}
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-colorp-c,
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-checker-bg,
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker {
  border-radius: 50% !important;
}
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker {
  border-radius: 50%;
}

.sp-container {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
}
