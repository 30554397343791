.gjs-cv-canvas {
  height: 100%;
  top: 0;
  width: 100%;
}

.gjs-badge {
  background-color: transparent;
  color: var(--colors-primary400);
}

/* Toolbar */
.gjs-toolbar {
  border-radius: var(--border-radius);
  background-color: rgb(252, 252, 255);
  box-shadow: 0px 10px 13px 0px rgba(10, 25, 39, 0.09);
}

.gjs-toolbar > div {
  display: flex;
}

.gjs-toolbar-item {
  color: #a1a4b8;
  font-size: 16px;
  padding: 10px 5px;
  transition: 0.3s ease all;
  width: auto;
}

.gjs-toolbar-item:first-child {
  padding-left: 10px
}

.gjs-toolbar-item:last-child {
  padding-right: 10px
}

.gjs-toolbar-item:hover {
  color: #26272d;
}

/* Canvas */
.gjs-cv-canvas .gjs-highlighter, .gjs-cv-canvas .gjs-highlighter-sel {
  outline: 1px solid var(--colors-primary400) !important;
}

/* Panel */
.gjs-pn-panel {
  padding: 0;
  position: relative;
  width: 100%;
}

.gjs-one-bg {
  background-color: #fff;
}

.gjs-two-color {
  color: #26272d;
}

.gjs-four-color, .gjs-four-color-h:hover  {
  color: #26272d;
}

/* Block */
.gjs-blocks-c {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
}

.gjs-block {
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid #e5e5ee;
  box-shadow: none;
  color: #a1a4b8;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  margin: 0;
  padding: 0;
  transition: 0.3s ease all;
  width: 100%;
}

.gjs-block-section {
  grid-column: span 2;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.gjs-block:hover {
  border-color: transparent;
  box-shadow: 0px 10px 13px 0px rgba(10, 25, 39, 0.08);
  color: #26272d;
}

.gjs-block-label {
  width: 100%;
}

.gjs-placeholder {
  border-color: transparent var(--colors-primary400) !important;
}

.gjs-placeholder-int {
  background-color: var(--colors-primary400) !important;
}

.gjs-sm-header {
  color: #26272d;
}

/* Layer */

.gjs-layer {
  background-color: rgba(254, 218, 214, 0.2);
  color: #26272d;
}

.gjs-layer-vis {
  display: none;
  pointer-events: none;
}

.gjs-layer-title {
  background-color: #fff;
  margin-left: -10px;
}

.gjs-layer-move {
  color: #a1a4b8;
  top: 10px;
  left: 5px;
  width: 20px;
}

.gjs-layer-count {
  color: #a1a4b8;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.025em;
  top: 15px;
  right: 15px;
}

.gjs-layer-name {
  color: #26272d;
  font-size: 12px;
  letter-spacing: -0.025em;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.gjs-layer-title {
  border-bottom-color: #f3f3f9;
  padding-top: 15px;
  padding-bottom: 15px;
}

.gjs-layer.gjs-hovered .gjs-layer-title {
  background-color: rgba(218, 198, 255, 0.2);
}

.gjs-layer.gjs-selected .gjs-layer-title {
  background-color: rgba(218, 198, 255, 0.5);
}

.gjs-layer-caret {
  color: #a1a4b8;
  top: 0;
  left: -12px; /* Set through trial and error */
}

/* Sector */
.gjs-sm-sector.gjs-sm-open {
  border-bottom: none;
}

.gjs-sm-sector .gjs-sm-title {
  background-color: transparent;
  border-bottom: 1px solid #f3f3f9;
  color: #26272d;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.2;
  padding: 25px 30px;
  pointer-events: none;
}

.gjs-sm-sector .gjs-sm-title .fa {
  display: none;
}

.gjs-sm-property .gjs-sm-label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1;
}

.gjs-sm-sector > .gjs-sm-properties > .gjs-sm-property {
  margin-bottom: 0;
  padding: 20px 30px;
}

.gjs-sm-properties > .gjs-sm-property:not(:last-child) {
  border-bottom-color: #f3f3f9;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.gjs-sm-sector .gjs-sm-property {
  float: none;
  width: 100%;
}

.gjs-sm-composite .gjs-sm-properties {
  padding: 0;
}

/* Resizer Picker */
[class*="gjs-resizer"] {
  display: none;
}

.gjs-trt-trait {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gjs-sm-sector > .gjs-sm-properties > .gjs-sm-property {
  padding: var(--wrapper-vertical-padding) var(--wrapper-horizontal-padding);
}

.gjs-sm-sector .gjs-sm-property, .gjs-clm-tags .gjs-sm-property {
  margin-bottom: var(--property-margin-bottom);
}
.gjs-sm-sector .gjs-sm-property:last-of-type, .gjs-clm-tags .gjs-sm-property:last-of-type {
  margin-bottom: 0;
}

.gjs-fields > .gjs-sm-field.gjs-sm-composite {
  border: none;
}

/* input block wrapper starts - eg. padding, border radius */
.gjs-fields > .gjs-sm-field.gjs-sm-composite {
  background-color: var(--colors-light100);
}
/* input block wrapper ends */

/* Temp fix, RTE dropdown panel position */
.cke.cke_panel.cke_ltr {
  top: -137px !important;
}
