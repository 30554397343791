#traits-container,
#styles-container,
#traits-container-en_US,
#styles-container-en_US {
  --border-radius: 4px;

  --input-gap: 10px;
  --input-padding: 10px;
  --input-height: 40px;
  --input-units-width: 55px;

  --wrapper-vertical-padding: 20px;
  --wrapper-horizontal-padding: 10px;

  --label-margin-bottom: 10px;

  --color-input-preview-size: 24px;

  --property-margin-bottom: 18px;

  --transition-fluid: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
