.gjs-sm-sector .gjs-sm-property .gjs-sm-btn,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn {
  transition: var(--transition-fluid);
  background-color: var(--colors-accent400);
  border-radius: var(--border-radius);
  box-shadow: 0px 10px 20px rgba(var(--colors-accent600rgb), 0.3);
  color: var(--colors-light100);
  font-family: var(--fonts-normal);
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-bold);
  height: 30px;
  line-height: var(--lineHeights-normal);
  text-shadow: none;
}
.gjs-sm-sector .gjs-sm-property .gjs-sm-btn:hover,
.gjs-sm-sector .gjs-sm-property .gjs-sm-btn:hover,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn:focus,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn:focus {
  box-shadow: 0px 13px 30px rgba(var(--colors-accent600rgb), 0.5);
  background-color: var(--colors-accent500);
}
