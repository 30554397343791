.gjs-sm-label, .gjs-label-wrp {
  margin-top: 0;
  margin-bottom: var(--label-margin-bottom);
}
.gjs-sm-label span, .gjs-label-wrp div {
  color: var(--colors-dark500);
  font-family: var(--fonts-normal);
  font-size: var(--fontSizes-10);
  font-weight: var(--fontWeights-bold);
  letter-spacing: var(--letterSpacings-normal);
  line-height: var(--lineHeights-normal);
  text-transform: uppercase;
}
