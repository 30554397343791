.gjs-radio-items {
  border-radius: var(--border-radius);
  border: 1px solid var(--colors-light700);
}

.gjs-radio-items .gjs-radio-item {
  align-items: center;
  background-color: var(--colors-light200);
  color: var(--colors-dark500);
  display: flex;
  flex: 1;
  border-left-color: var(--colors-light100);
  border-right: 1px solid var(--colors-light700);
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-bold);
  justify-content: center;
}

.gjs-radio-items .gjs-radio-item .gjs-radio-item-label {
  width: 100%;
  padding: 10px 6px;
}

.gjs-radio-items .gjs-radio-item:last-of-type {
  border-right: none;
}

.gjs-radio-items .gjs-radio-item:hover {
  color: var(--colors-dark700);
}

.gjs-radio-item input:checked+.gjs-radio-item-label {
  background-color: var(--colors-light100);
  color: var(--colors-primary300);
  box-shadow: 0px 2px 3px rgba(var(--colors-dark600rgb), 0.1);
}

.gjs-radio-item input:checked+.gjs-radio-item-label:hover {
  color: var(--colors-primary300);
}
